<template>
    <div>
        <div class="main-title">话题列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">
                            开始设置您的话题吧
                        </li>
                    </ul>
                </div>
            </div>

            <div style="position: relative; margin-bottom: 10px">
                <div class="margin-l-sm fl-l margin-t-sm">
                    <el-input v-model="searchData.name" placeholder="名称" type="small" clearable></el-input>
                </div>
                <div class="margin-l-sm fl-l margin-t-sm">
                    <el-input v-model="searchData.theme" placeholder="话题" type="small" clearable></el-input>
                </div>
                <div class="margin-l-sm fl-l margin-t-sm">
                    <el-select v-model="searchData.status" placeholder="请选择状态" size="small" clearable>
                        <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="margin-l-sm fl-l margin-t-sm">
                    <el-select v-model="searchData.recommend" placeholder="请选择推荐" size="small" clearable>
                        <el-option
                        v-for="item in recommendOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <span class="margin-l-sm fl-l margin-t-sm">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>
                <span class="margin-l-sm fl-r margin-t-sm">
                    <el-button type="primary" icon="el-icon-plus" size="small"
                        @click="$router.push('/socialTheme/add')">添加</el-button>
                </span>
            </div>

            <div class="table-content">
                <el-table :header-cell-style="{ padding: 0, 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }" ref="singleTable" :data="tableData" highlight-current-row
                    v-loading="loading" style="width: 100%; background: #fff">
                    <el-table-column label="ID" property="id" width="80">
                    </el-table-column>
                    <el-table-column label="名称" property="name">
                    </el-table-column>
                    <el-table-column label="话题" property="theme">
                    </el-table-column>
                    <el-table-column width="150" label="图标">
                        <template slot-scope="scope">
                            <div v-if="scope.row.icon != ''">
                                <el-image style="width: 100px;" :src="imageUrl + scope.row.icon">
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status">上架</el-tag>
                            <el-tag  type="info" v-else>下架</el-tag>
                        </template>
                    </el-table-column>

                     <el-table-column label="排序">
                        <template slot-scope="scope">
                            <el-input-number size="mini" v-model="scope.row.sort"
                                @change="handleChange(scope.row, sort, getList)"></el-input-number>
                        </template>
                    </el-table-column>

                    <el-table-column property="created_at" label="创建时间">
                    </el-table-column>
                    <el-table-column property="updated_at" label="更新时间">
                    </el-table-column>


                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span>
                                <span>
                                    <el-link type="primary"
                                        @click="$router.push('/socialTheme/edit/' + scope.row.id)">编辑</el-link>
                                </span>
                            </span>
                            <span>
                                -
                                <span v-if="!scope.row.status">
                                    <el-link type="primary" @click="setStatus(scope.row.id,statusOptions[0].value)">上架</el-link>
                                </span>
                                 <span v-else>
                                    <el-link type="danger" @click="setStatus(scope.row.id,statusOptions[1].value)">下架</el-link>
                                </span>
                            </span>
                            <span>
                                -
                                <span v-if="!scope.row.recommend">
                                    <el-link type="primary" @click="doRecommend(scope.row.id)">推荐</el-link>
                                </span>
                                 <span v-else>
                                    <el-link type="danger" @click="doRecommend(scope.row.id)">取消推荐</el-link>
                                </span>
                            </span>
                            <span>
                                -
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row.id)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>

                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from 'lodash'
export default {
    name: "socialThemeList",
    data() {
        return {
            searchData: {
                name: '',
                theme: '',
                status: '',
                recommend: ''
            },
            statusOptions: [
                {
                    label: "上架",
                    value: 1
                },
                {
                    label: "下架",
                    value: 0
                }
            ],
            recommendOptions: [
                {
                    label: "是",
                    value: 1
                },
                {
                    label: "否",
                    value: 0
                }
            ],
            page: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            tableData: [],
            imageUrl: config.imageUrl,
            sortData: {},
            model: "SocialTheme",
        };
    },
    methods: {
        ...mapActions('socialTheme', ['list', 'recommend', 'del','updateStatus','sort']),
        async deleted(id) {
            const { res_info } = await this.del({ model: this.model, id: id})
            if (res_info != 'ok') return
            this.$message.success('删除成功！')
            this.getList()
        },
        async doRecommend(id){
            const { res_info } = await this.recommend({ id:id })
            if(res_info != 'ok') return
            this.$message.success("操作成功!")
            this.getList()
        },
        async setStatus(id,status){
            console.log(this.model,id,status)
            const { res_info } = await this.updateStatus({model: this.model + 'Model',id: id,status: status})
            if(res_info != 'ok') return
            this.$message.success("操作成功!")
            this.getList()
        },
        handleChange: _.debounce(async (res, sort, getList) => {
            const { data, res_info } = await sort(res)
            if (res_info != 'ok') return
            getList()
        }, 1000),
        async getList() {
            this.loading = true
            let form = {
                ...this.searchData,
                page: this.page,
                pageSize: this.pageSize
            }
            const { data } = await this.list(form)
            this.tableData = data.list
            this.total = data.total
            this.loading = false
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.getList()
        },
        setPage(page) {
            this.page = page
            this.getList()
        },
        search() {
            this.page = 1
            this.getList()
        },
    },
    mounted() {
        this.getList()
    },
};
</script>


